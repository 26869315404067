import {
  serialize,
} from "./utils";

import {
  cache,
  isCached
} from "./cache";

import { DEFAULT_CATEGORIES } from "../config/config";

// const cache = getCache()

export const checkURL = (url) => {
  if (url) {
    if (!url.startsWith('http')) {
      return 0;
    }
    try {
      const httpUrl = new URL(url);
      if (httpUrl.hostname.includes('.') && !url.endsWith('.')) {
        return 1;
      }
    } catch {}
    return 2;
  } else {
    return 1
  }
}

export const checkNullFields = (mode, row) => {
  for (const key in row) {
    if (key === "state") {
      continue;
    }
    if (mode === "trivia" && key === "fact") {
      continue;
    }
    if (row[key] === "") {
      return false;
    }
  }
  return true;
};

export const checkLocation = async (row) => {
  if (row["country"]) {
    let location = null
    if (row["country"] === "U.S.A") {
      location = {
        city: row["city"],
        state: row["state"],
        country: "United States",
      };
    } else {
      location = {
        city: row["city"],
        state: row["state"],
        country: row["country"],
      };
    }

    let key = [row["city"], row["state"], row["country"],].join("|")
    if (!(isCached(key))) {
      let querystring = serialize(location);
      let http_request = `https://nominatim.openstreetmap.org/search?${querystring}&format=json`;
      const response = await fetch(http_request, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const reader = response.body.getReader();
      // eslint-disable-next-line
      const { value: foundedLocations, done } = await reader.read();
      const decoder = new TextDecoder();
      const locations = JSON.parse(decoder.decode(foundedLocations))
      let success = locations.length > 0
      cache.setItem(key, success)
      return success
    } else {
      let success = cache.getItem(key) === 'true'
      return success
    }
  }
  return false;
};

export const checkCategory = (category) => {
  if (category) {
    return DEFAULT_CATEGORIES.includes(category);
  }
  return true;
};

export const checkCorrectAnswer = (correct_answer) => {
  if (correct_answer) {
    return ["A", "B", "C", "D"].includes(correct_answer);
  }
  return true;
};

export const validateRecord = async (mode, row) => {
  let status = "";
  let note = [];

  let fieldValid = checkNullFields(mode, row);
  let locationValid = await checkLocation(row);
  let categoryValid = checkCategory(row.category);
  let answerValid = checkCorrectAnswer(row.correct_answer);
  let urlValid = checkURL(row.url)

  if (!fieldValid) {
    status = "Invalid";
    note.push("Missing field(s)");
  }
  if (!categoryValid) {
    status = "Invalid";
    note.push("Wrong category");
  }
  if (!answerValid) {
    status = "Invalid";
    note.push("Wrong correct answer");
  }
  if (!locationValid) {
    status = "Invalid";
    note.push("Wrong location");
  }
  if (urlValid !== 1) {
    status = "Invalid";
    if (urlValid === 0) {
      note.push("URL must start with http(s)")
    } else {
      note.push("Invalid URL")
    }
  }
  return [status, note.join(", ")];
};
