/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "testcustomfactapi",
            "endpoint": "https://ohqb4nt100.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:da418503-acb5-42c7-b092-a22b47a5a6d4",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Kt3WdqGZ2",
    "aws_user_pools_web_client_id": "6f30qsdb0rundegcps35du4toe",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
