import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import "./popup.css";

export default function Popup({ onSubmit, onCancel }) {
  const [open, setOpen] = useState(true);
  const [termsChecked, setTermsChecked] = useState(false);
  const [legalChecked, setLegalChecked] = useState(false);

  const handleClose = () => {
    setOpen(false);
    onCancel();
  };

  const handleTermsCheckboxChange = (event) => {
    setTermsChecked(event.target.checked);
  };

  const handleLegalCheckboxChange = (event) => {
    setLegalChecked(event.target.checked);
  };

  const handleProcessClick = () => {
    if (termsChecked && legalChecked) {
      onSubmit();
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Terms of Use and Privacy</DialogTitle>
        <DialogContent className="diag-content">
          <DialogContentText>
            Please read and accept the Terms of Use and Privacy.
          </DialogContentText>
          <Checkbox
            checked={termsChecked}
            onChange={handleTermsCheckboxChange}
            color="primary"
            inputProps={{ "aria-label": "terms of condition checkbox" }}
          />
          <label>
            <span>
              I accept the{" "}
              <a href="https://www.aisin.com/en/use/" rel="noopener noreferrer" target="_blank">
                Terms of Use
              </a>
            </span>
          </label>
          <br />
          <Checkbox
            checked={legalChecked}
            onChange={handleLegalCheckboxChange}
            color="primary"
            inputProps={{ "aria-label": "legal checkbox" }}
          />
          <label>
            <span>
              I accept the{" "}
              <a href="https://www.aisin.com/en/privacy/" rel="noopener noreferrer" target="_blank">
                Privacy
              </a>
            </span>
          </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleProcessClick}
            color="primary"
            variant="contained"
            disabled={!termsChecked || !legalChecked}
          >
            Process
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
