import { STORAGE } from "../config/config.js"

class Cache {
  constructor() {
    this.storage = {};
  }

  setItem(location, status) {
    this.storage[location] = status.toString(); // mimic Storage's behaviour
  }

  getItem(location) {
    return this.storage[location] || null;
  }

  clear() {
    Object.keys(this.storage).forEach((key) => {
      delete this.storage[key];
    });
  }
}

function getCache() {
  try {
    console.log(STORAGE)
    const storageType = "localStorage";
    const cache = window[storageType];
    if (cache) {
      return cache;
    }
  } catch(e) { }
  return new Cache();
}

export const cache = getCache();

export function isCached(location) {
  return ["true", "false"].includes(cache.getItem(location));
};
