import { toTitleCase } from "./utils";
import { validateRecord } from "./validation";

export const parseCSV = (csvContent, delimiter) => {
  const rows = csvContent.split("\n");
  const headers = rows[0].split(delimiter).map((header) => header.trim());

  const data = [];

  for (let i = 1; i < rows.length; i++) {
    const values = rows[i].split(delimiter).map((value) => value.trim());
    const row = {};

    for (let j = 0; j < headers.length; j++) {
      if (!values[j]) {
        row[headers[j]] = "";
      }
      row[headers[j]] = values[j];
    }

    data.push(row);
  }

  return data;
};

export const processFileData = async (mode, data) => {
  let processedData = [];
  for (let index = 0; index < data.length; index++) {
    const row = data[index];
    if (row.category) {
      row.category = toTitleCase(row.category);
    } else {
      row.category = ""
    }
    let [status, note] = await validateRecord(mode, row);
    processedData.push({
      orderNum: index,
      ...row,
      status: status,
      note: note,
    });
  }
  return processedData;
};


export const handleSubmit = async (
  API_ENDPOINT,
  mode,
  username,
  tableData,
  setTableData,
  setLoading2,
  setSubmitSuccess
) => {
  try {
    setLoading2(false);
    setSubmitSuccess(false);
    const submitData = {
      mode: mode,
      username: username,
      records: []
    }
    for (let i = 0; i < tableData.length; i++) {
      const record = JSON.parse(JSON.stringify(tableData[i]));
      if (record['status'] !== "Invalid") {
        delete record['status']
        delete record['note']
        if (record['country'] === "United States") {
          record['country'] = "U.S.A"
        }
        submitData["records"].push(record)
      }
    }
    const response = await fetch(API_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(submitData),
    });
    if (!response.ok) {
      console.error("HTTP error! Status:", response.status);
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        setLoading2(true);
        setSubmitSuccess(true);
        break;
      }
      const chunk = decoder.decode(value, { stream: true });
      const jsonObjects = chunk.split("\n");
      for (const jsonObj of jsonObjects) {
        if (jsonObj) {
          try {
            const responseData = jsonObj
              .split("\n")
              .map((jsonString) => JSON.parse(jsonString));

            setTableData((prevData) => {
              const updatedData = prevData.map((record) => {
                const matchingResponse = responseData.find(
                  (response) => response.orderNum === record.orderNum
                );

                if (matchingResponse) {
                  return {
                    ...record,
                    status: matchingResponse.success ? "Done" : "Error",
                    note: matchingResponse.success
                      ? ""
                      : matchingResponse.reason,
                  };
                } else {
                  return record;
                }
              });

              return updatedData;
            });
          } catch (error) {
            console.error(error);
          }
        }
      }
    }
  } catch (error) {
    console.error("Error during POST request:", error);
  }
};
