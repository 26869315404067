import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import "./popup.css";
import trivias_template from "../files/trivias_template.csv"
import fact_template from "../files/fact_template.csv"

export default function HelpPopup({ onCancel }) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    onCancel();
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>How to format CSV</DialogTitle>
        <DialogContent className="diag-content">
          <DialogContentText>
            You can download the template for each mode here:
            <ul>
              <li>
                <a href={trivias_template} download="trivias_template.csv">
                  trivias_template.csv
                </a>
              </li>
              <li>
                <a href={fact_template} download="fact_template.csv">
                  fact_template.csv
                </a>
              </li>
            </ul>
            In Trivia mode:
            <ul>
              <li>city: City name.</li>
              <li>
                state (Optional): State name, as some location will not have
                state so the “state” is optional.
              </li>
              <li>country: Country name</li>
              <li>question: Content of the question.</li>
              <li>
                category: Category of the question (Must be one of: Arts,
                Business , Food, Shopping, Sports).
              </li>
              <li>A, B, C, D: Multiple-choice answers for the question.</li>
              <li>
                correct_answer: The correct answer of the question, must be one
                letter A, B, C or D only.
              </li>
              <li>
                fact (optional): Fact for the correct answer of the question.
              </li>
              <li>url: Call to Action</li>
            </ul>
            In Fact mode:
            <ul>
              <li>city: City name.</li>
              <li>
                state (Optional): State name, as some location will not have
                state so the “state” is optional.
              </li>
              <li>country: Country name</li>
              <li>question: Content of the question.</li>
              <li>
                category: Category of the question (Must be one of: Arts,
                Business, Food, Shopping, Sports).
              </li>
              <li>fact: Fact for the correct answer of the question.</li>
              <li>url: Call to Action</li>
            </ul>
            <strong>Note:</strong>
            <ol>
              <li>
                Delimiter of values must be “|” (Comma, colon, etc. are very
                easy to appear in the content of question – answers.)
              </li>
              <li>
                If the country is United States, please write “U.S.A”. All other
                countries, just write in full as usual (Vietnam, Russia, Brazil,
                …)
              </li>
            </ol>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
