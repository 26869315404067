import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { cache } from "../components/cache";
import trivias_template from "../files/trivias_template.csv"
import fact_template from "../files/fact_template.csv"
import "./popup.css";

export default function Example() {
  const [open, setOpen] = useState(cache.getItem("shownExample") !== "true");

  const handleClose = () => {
    cache.setItem("shownExample", "true");
    setOpen(false);
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        scroll="paper"
        maxWidth="lg"
      >
        <DialogTitle>Example for creating custom csv data</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogTitle>1. Instruction</DialogTitle>
          <Typography paragraph>
            The delimiter for columns in a csv file is "|"
          </Typography>
          <Typography paragraph>
            Trivia Mode - 12 columns: city, state, country, question, category,
            A, B, C, D, correct_answer, fact, url
          </Typography>
          <Typography paragraph>
            Fact Mode - 7 columns: city, state, country, question, fact,
            category, url
          </Typography>
          <DialogTitle>2. Example</DialogTitle>
          <Typography paragraph>
            For example, if you want to add custom data with info:
          </Typography>
          <Typography paragraph>
            Melissa|Texas|U.S.A|Which event in Melissa, TX, is known for
            showcasing local artists and craftsmen?|Arts|Melissa Country
            Fair|Melissa Arts Festival|Melissa Farmer's Market|Melissa
            Music Nights|C|Melissa Arts Festival in Melissa, TX, is known
            for showcasing local artists -
            https://cardinalconnection.net/11334/news/melissa-isd-to-hold-first-ever-heart-for-the-arts-festival
          </Typography>
          <Typography paragraph>Your csv file will be:</Typography>
          <Typography paragraph>
            city|state|country|question|category|A|B|C|D|correct_answer|fact|url
          </Typography>
          <Typography paragraph>
            Ho Chi Minh||Vietnam|What is the name of the stadium in Ho Chi Minh
            City that is primarily used for football matches?|Sports|Phu Tho
            Stadium|Thong Nhat Stadium|Tan Binh Stadium| Quan Khu 7
            Stadium|B|Thong Nhat Stadium is primarily used for football
            matches|https://cardinalconnection.net/11334/news/melissa-isd-to-hold-first-ever-heart-for-the-arts-festival
          </Typography>
          <DialogTitle>3. Note</DialogTitle>
          You can download the template for each mode here:
            <ul>
            <li>
                <a href={trivias_template} download="trivias_template.csv">
                  trivias_template.csv
                </a>
              </li>
              <li>
                <a href={fact_template} download="fact_template.csv">
                  fact_template.csv
                </a>
              </li>
            </ul>
          <Typography paragraph>
            For more instruction, click "?" next to "Upload File" button
          </Typography>
        </DialogContent>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
      </Dialog>
    </>
  );
}
