import { createTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    customGray: {
      main: '#f0f0f0',
    //   light: '#E9DB5D',
    //   dark: '#A29415',
    //   contrastText: '#3c3c3c',
    },
  },
});

export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });