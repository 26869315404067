import React, { useState, useRef, useEffect } from "react";
import "./App.css";
import { Authenticator } from "@aws-amplify/ui-react";
import {
  Button,
  IconButton,
  Tooltip,
  Switch,
  Stack,
  Typography,
  CircularProgress,
  ThemeProvider,
  Menu,
  MenuItem,
} from "@mui/material";
import Popup from "./layout/popup.js";
import HelpPopup from "./layout/help.js";
import { theme, VisuallyHiddenInput } from "./components/muiColor.js";

import UploadFileIcon from "@mui/icons-material/UploadFile.js";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined.js";
import PublishIcon from "@mui/icons-material/Publish.js";
import LogoutIcon from "@mui/icons-material/Logout.js";
import { validateRecord } from "./components/validation.js";
import {
  parseCSV,
  processFileData,
  handleSubmit,
} from "./components/fileHandling.js";
import {
  checkCategory,
  checkCorrectAnswer,
  checkURL,
} from "./components/validation.js";
import { toTitleCase } from "./components/utils.js";
import { API_ENDPOINT, DEFAULT_CATEGORIES } from "./config/config.js";
import { AddRowForm } from "./layout/addRowForm.js";
import Example from "./layout/example.js";

const App = () => {
  var bgColors = { triviaColor: "#F552C5", factColor: "#33B4E3" };
  const [tableData, setTableData] = useState([]);
  const [prevModeData, setPrevModeData] = useState([]);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [checked, setChecked] = useState(false);
  const [mode, setMode] = useState("trivia");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const inputFile = useRef(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editField, setEditField] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showHelpPopup, setShowHelpPopup] = useState(false);
  const [showAddRow, setShowAddRow] = useState(false);
  const [vertical, setVertical] = useState(
    window.innerWidth < window.innerHeight
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const timeoutRef = useRef(null);
  const touchPositionRef = useRef({ x: 0, y: 0 });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      setVertical(width < height);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleContextMenu = (event, index) => {
    event.preventDefault();
    setAnchorEl({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
    setSelectedIndex(index);
  };

  const handleTouchStart = (event, index) => {
    const touch = event.touches[0];
    touchPositionRef.current = { x: touch.clientX, y: touch.clientY };
    timeoutRef.current = setTimeout(() => {
      setAnchorEl({ mouseX: touch.clientX - 2, mouseY: touch.clientY - 4 });
      setSelectedIndex(index);
    }, 500); // Adjust the delay according to your preference
  };

  const handleTouchEnd = () => {
    clearTimeout(timeoutRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteRow = () => {
    console.log("Deleted row with index:", selectedIndex);
    setTableData((prevData) => {
      return prevData.filter((_, i) => i !== selectedIndex);
    });
    handleClose();
  };

  const cleanUp = () => {
    setPrevModeData(tableData);
    setTableData([]);
    setSubmitSuccess(false);
    setLoading(false);
    setLoading2(true);
    handleReset();
  };

  const toggleMode = (event) => {
    cleanUp();
    setTableData(prevModeData);
    setMode((prevMode) => (prevMode === "trivia" ? "fact" : "trivia"));
    setChecked(event.target.checked);
  };

  const handleFileChange = async (e) => {
    e.preventDefault();
    let file = e.target.files[0];

    if (file) {
      setTableData([]);
      setSubmitSuccess(false);
      const reader = new FileReader();

      reader.onload = async (event) => {
        setLoading(true);
        const content = event.target.result;
        const parsedData = parseCSV(content, "|");
        const processedData = await processFileData(mode, parsedData);
        setTableData(processedData);
        setLoading(false);
      };

      reader.readAsText(file);
    }
  };
  const handleReset = () => {
    if (inputFile.current) {
      inputFile.current.value = "";
      inputFile.current.type = "text";
      inputFile.current.type = "file";
    }
  };
  const handleFileSubmit = (username) => {
    handleSubmit(
      API_ENDPOINT,
      mode,
      username,
      tableData,
      setTableData,
      setLoading2,
      setSubmitSuccess
    );
  };

  const handleCellClick = (index, field) => {
    setEditIndex(index);
    setEditField(field);
  };

  const handleCellChange = (value) => {
    setTableData((prevTableData) => {
      const updatedTableData = [...prevTableData];
      updatedTableData[editIndex][editField] = value;
      return updatedTableData;
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleCellBlur();
    }
  };

  const handleCellBlur = async () => {
    if (editIndex !== null && editField !== "") {
      setTableData((prevTableData) => {
        const updatedTableData = [...prevTableData];
        updatedTableData[editIndex][editField] =
          updatedTableData[editIndex][editField].trim();
        return updatedTableData;
      });
      let { status, note, ...new_record } = tableData[editIndex];
      [status, note] = await validateRecord(mode, new_record);
      tableData[editIndex]["status"] = status;
      tableData[editIndex]["note"] = note;
    }
    setEditIndex(null);
    setEditField("");
  };

  const handleCategoryChange = (newValue, rowIndex) => {
    setTableData((prevTableData) => {
      const updatedTableData = [...prevTableData];
      updatedTableData[rowIndex]["category"] = newValue;
      let validCategory = checkCategory(newValue);
      if (validCategory) {
        let note = updatedTableData[rowIndex]["note"].split(", ");
        note = note.filter(function (e) {
          return e !== "Wrong category";
        });
        updatedTableData[rowIndex]["note"] = note.join(", ");
        if (note.length === 0) {
          updatedTableData[rowIndex]["status"] = "";
        }
      }
      setEditIndex(null);
      setEditField("");
      return updatedTableData;
    });
  };

  const handleCorrectAnswer = (newValue, rowIndex) => {
    setTableData((prevTableData) => {
      const updatedTableData = [...prevTableData];
      updatedTableData[rowIndex]["correct_answer"] = newValue;
      let validAnswer = checkCorrectAnswer(newValue);
      if (validAnswer) {
        let note = updatedTableData[rowIndex]["note"].split(", ");
        note = note.filter(function (e) {
          return e !== "Wrong correct answer";
        });
        updatedTableData[rowIndex]["note"] = note.join(", ");
        if (note.length === 0) {
          updatedTableData[rowIndex]["status"] = "";
        }
      }
      return updatedTableData;
    });
  };

  const handlePopupCancel = () => {
    setShowPopup(false);
  };

  const handlePopup = () => {
    setShowPopup(true);
  };

  const handleHelpPopupCancel = () => {
    setShowHelpPopup(false);
  };

  const handleHelpPopup = () => {
    setShowHelpPopup(true);
  };

  const handlePopupSubmit = (username) => {
    setShowPopup(false);
    handleFileSubmit(username);
  };

  const handleAddRowCancel = () => {
    setShowAddRow(false);
  };

  const handleAddRow = () => {
    setShowAddRow(true);
  };

  const handleAddRowSubmit = () => {
    setShowAddRow(false);
  };

  return (
    <Authenticator>
      {({ signOut, user }) => (
        <ThemeProvider theme={theme}>
          <div className="App">
            <Example />
            <div
              className="App-header"
              style={
                mode === "fact"
                  ? { backgroundColor: bgColors.factColor }
                  : { backgroundColor: bgColors.triviaColor }
              }
            >
              <h1 className="title">LocoGenius Custom {toTitleCase(mode)}</h1>
              <div className="info">
                <div className="user-info">
                  <p style={{ marginBlock: 5, color: "white" }}>
                    Hello <em>{user.username}</em>
                  </p>
                  <Button
                    // eslint-disable-next-line
                    onClick={(user) => (signOut(user), cleanUp())}
                    variant="contained"
                    color="customGray"
                    size="small"
                    startIcon={<LogoutIcon />}
                  >
                    Sign out
                  </Button>
                </div>
              </div>
            </div>
            <div className={vertical ? "Upload-vertical" : "Upload"}>
              <Stack
                direction="row"
                spacing={0}
                alignItems="center"
                className="custom-stack"
              >
                <Typography>Trivia</Typography>
                <Switch
                  checked={checked}
                  onChange={toggleMode}
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "white", // Change this to your desired color
                    },
                    "& .MuiSwitch-track": {
                      backgroundColor: bgColors.triviaColor,
                    },
                  }}
                />
                <Typography>Fact</Typography>
              </Stack>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "space-around",
                }}
              >
                <Button
                  component="label"
                  color="customGray"
                  variant="contained"
                  tabIndex={-1}
                  size="small"
                  startIcon={<UploadFileIcon />}
                  style={{ width: "140px", marginLeft: "28px" }}
                >
                  Upload file
                  <VisuallyHiddenInput
                    type="file"
                    accept=".csv"
                    ref={inputFile}
                    onChange={async (e) => await handleFileChange(e)}
                  />
                </Button>
                <Tooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        How to format csv file?
                      </Typography>
                      Click for more details.
                    </React.Fragment>
                  }
                >
                  <IconButton
                    size="small"
                    onClick={handleHelpPopup}
                    style={{ marginLeft: "4px" }}
                  >
                    <HelpOutlineOutlinedIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
                {showHelpPopup && (
                  <HelpPopup onCancel={handleHelpPopupCancel} />
                )}
              </div>
              <div style={{ width: "140px", textAlign: "center" }}>
                <Button
                  disabled={tableData.length === 0}
                  onClick={handlePopup}
                  variant="contained"
                  color="customGray"
                  size="small"
                  startIcon={<PublishIcon />}
                  style={{ width: "100px" }}
                >
                  Submit
                </Button>
              </div>
              {showPopup && (
                <Popup
                  onSubmit={() => handlePopupSubmit(user.username)}
                  onCancel={handlePopupCancel}
                />
              )}
            </div>
            <div className="App-body">
              <div className={"Upload-status"}>
                <p>{submitSuccess ? "Data submitted successfully!" : ""}</p>
                {((tableData.length === 0 && loading) ||
                  (!submitSuccess && !loading2)) && <CircularProgress />}
              </div>
              <div className="container">
                {tableData.length > 0 && (
                  <div className="table-wrapper">
                    <table className="table">
                      <thead>
                        <tr
                          className={
                            mode === "trivia" ? "thread-trivia" : "thread-fact"
                          }
                        >
                          <th>No.</th>
                          <th className="city-col">City</th>
                          <th>State (Optional)</th>
                          <th>Country</th>
                          <th
                            className={
                              mode === "trivia"
                                ? "question-col"
                                : "question-col-fact"
                            }
                          >
                            Question
                          </th>
                          <th>Category</th>
                          {mode === "trivia" && (
                            <>
                              <th>Option A</th>
                              <th>Option B</th>
                              <th>Option C</th>
                              <th>Option D</th>
                              <th>Correct Answer</th>
                            </>
                          )}
                          <th
                            className={
                              mode === "trivia" ? "fact-col" : "fact-col-fact"
                            }
                          >
                            {mode === "trivia"
                              ? "Explanation Content (Optional)"
                              : "Fact"}
                          </th>
                          <th className="url-col">Call to Action</th>
                          <th>Status</th>
                          <th className="note-col">Note</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((row, index) => {
                          return (
                            <tr
                              key={index}
                              onContextMenu={(event) =>
                                handleContextMenu(event, index)
                              }
                              onTouchStart={(event) =>
                                handleTouchStart(event, index)
                              }
                              onTouchEnd={handleTouchEnd}
                              onTouchCancel={handleTouchEnd}
                            >
                              <td>{index + 1}</td>
                              <td
                                onClick={() => handleCellClick(index, "city")}
                                className={
                                  editIndex === index && editField === "city"
                                    ? "editable-cell"
                                    : row["city"] === "" ||
                                      row["note"].includes("location")
                                    ? "null-cell"
                                    : ""
                                }
                              >
                                {editIndex === index && editField === "city" ? (
                                  <textarea
                                    autoFocus
                                    value={row["city"]}
                                    onChange={(e) =>
                                      handleCellChange(e.target.value)
                                    }
                                    onBlur={handleCellBlur}
                                    onKeyPress={handleKeyPress}
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                ) : (
                                  row["city"]
                                )}
                              </td>
                              <td
                                onClick={() => handleCellClick(index, "state")}
                                className={
                                  editIndex === index && editField === "state"
                                    ? "editable-cell"
                                    : row["note"].includes("location")
                                    ? "null-cell"
                                    : ""
                                }
                              >
                                {editIndex === index &&
                                editField === "state" ? (
                                  <textarea
                                    autoFocus
                                    value={row["state"]}
                                    onChange={(e) =>
                                      handleCellChange(e.target.value)
                                    }
                                    onBlur={handleCellBlur}
                                    onKeyPress={handleKeyPress}
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                ) : (
                                  row["state"]
                                )}
                              </td>
                              <td
                                onClick={() =>
                                  handleCellClick(index, "country")
                                }
                                className={
                                  editIndex === index && editField === "country"
                                    ? "editable-cell"
                                    : row["country"] === "" ||
                                      row["note"].includes("location")
                                    ? "null-cell"
                                    : ""
                                }
                              >
                                {editIndex === index &&
                                editField === "country" ? (
                                  <textarea
                                    autoFocus
                                    value={row["country"]}
                                    onChange={(e) =>
                                      handleCellChange(e.target.value)
                                    }
                                    onBlur={handleCellBlur}
                                    onKeyPress={handleKeyPress}
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                ) : (
                                  row["country"]
                                )}
                              </td>
                              <td
                                onClick={() =>
                                  handleCellClick(index, "question")
                                }
                                className={
                                  editIndex === index &&
                                  editField === "question"
                                    ? "editable-cell"
                                    : row["question"] === ""
                                    ? "null-cell"
                                    : ""
                                }
                              >
                                {editIndex === index &&
                                editField === "question" ? (
                                  <textarea
                                    autoFocus
                                    value={row["question"]}
                                    onChange={(e) =>
                                      handleCellChange(e.target.value)
                                    }
                                    onBlur={handleCellBlur}
                                    onKeyPress={handleKeyPress}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  />
                                ) : (
                                  row["question"]
                                )}
                              </td>
                              <td
                                className={
                                  !DEFAULT_CATEGORIES.includes(row["category"])
                                    ? "null-cell"
                                    : ""
                                }
                                onClick={() =>
                                  handleCellClick(index, "category")
                                }
                              >
                                {editIndex === index &&
                                editField === "category" ? (
                                  <select
                                    value={row["category"]}
                                    onChange={(e) =>
                                      handleCategoryChange(
                                        e.target.value,
                                        index
                                      )
                                    }
                                  >
                                    {DEFAULT_CATEGORIES.map(
                                      (category, index) => (
                                        <option key={index} value={category}>
                                          {category}
                                        </option>
                                      )
                                    )}
                                  </select>
                                ) : (
                                  row["category"]
                                )}
                              </td>
                              {mode === "trivia" && (
                                <>
                                  <td
                                    onClick={() => handleCellClick(index, "A")}
                                    className={
                                      editIndex === index && editField === "A"
                                        ? "editable-cell"
                                        : row["A"] === ""
                                        ? "null-cell"
                                        : ""
                                    }
                                  >
                                    {editIndex === index &&
                                    editField === "A" ? (
                                      <textarea
                                        autoFocus
                                        value={row["A"]}
                                        onChange={(e) =>
                                          handleCellChange(e.target.value)
                                        }
                                        onBlur={handleCellBlur}
                                        onKeyPress={handleKeyPress}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                      />
                                    ) : (
                                      row["A"]
                                    )}
                                  </td>
                                  <td
                                    onClick={() => handleCellClick(index, "B")}
                                    className={
                                      editIndex === index && editField === "B"
                                        ? "editable-cell"
                                        : row["B"] === ""
                                        ? "null-cell"
                                        : ""
                                    }
                                  >
                                    {editIndex === index &&
                                    editField === "B" ? (
                                      <textarea
                                        autoFocus
                                        value={row["B"]}
                                        onChange={(e) =>
                                          handleCellChange(
                                            e.target.value,
                                            index
                                          )
                                        }
                                        onBlur={handleCellBlur}
                                        onKeyPress={handleKeyPress}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                      />
                                    ) : (
                                      row["B"]
                                    )}
                                  </td>
                                  <td
                                    onClick={() => handleCellClick(index, "C")}
                                    className={
                                      editIndex === index && editField === "C"
                                        ? "editable-cell"
                                        : row["C"] === ""
                                        ? "null-cell"
                                        : ""
                                    }
                                  >
                                    {editIndex === index &&
                                    editField === "C" ? (
                                      <textarea
                                        autoFocus
                                        value={row["C"]}
                                        onChange={(e) =>
                                          handleCellChange(e.target.value)
                                        }
                                        onBlur={handleCellBlur}
                                        onKeyPress={handleKeyPress}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                      />
                                    ) : (
                                      row["C"]
                                    )}
                                  </td>
                                  <td
                                    onClick={() => handleCellClick(index, "D")}
                                    className={
                                      editIndex === index && editField === "D"
                                        ? "editable-cell"
                                        : row["D"] === ""
                                        ? "null-cell"
                                        : ""
                                    }
                                  >
                                    {editIndex === index &&
                                    editField === "D" ? (
                                      <textarea
                                        autoFocus
                                        value={row["D"]}
                                        onChange={(e) =>
                                          handleCellChange(e.target.value)
                                        }
                                        onBlur={handleCellBlur}
                                        onKeyPress={handleKeyPress}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                      />
                                    ) : (
                                      row["D"]
                                    )}
                                  </td>
                                  <td
                                    className={
                                      !["A", "B", "C", "D"].includes(
                                        row["correct_answer"]
                                      )
                                        ? "null-cell"
                                        : ""
                                    }
                                    onClick={() =>
                                      handleCellClick(index, "correct_answer")
                                    }
                                  >
                                    {editIndex === index &&
                                    editField === "correct_answer" ? (
                                      <select
                                        value={row["correct_answer"]}
                                        onChange={(e) =>
                                          handleCorrectAnswer(
                                            e.target.value,
                                            index
                                          )
                                        }
                                      >
                                        {["A", "B", "C", "D"].map(
                                          (correct_answer, index) => (
                                            <option
                                              key={index}
                                              value={correct_answer}
                                            >
                                              {correct_answer}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    ) : (
                                      row["correct_answer"]
                                    )}
                                  </td>
                                </>
                              )}
                              <td
                                onClick={() => handleCellClick(index, "fact")}
                                className={
                                  editIndex === index && editField === "fact"
                                    ? "editable-cell"
                                    : row["fact"] === "" && mode === "fact"
                                    ? "null-cell"
                                    : ""
                                }
                              >
                                {editIndex === index && editField === "fact" ? (
                                  <textarea
                                    autoFocus
                                    value={row["fact"]}
                                    onChange={(e) =>
                                      handleCellChange(e.target.value)
                                    }
                                    onBlur={handleCellBlur}
                                    onKeyPress={handleKeyPress}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  />
                                ) : (
                                  row["fact"]
                                )}
                              </td>
                              <td
                                onClick={() => handleCellClick(index, "url")}
                                className={
                                  editIndex === index && editField === "url"
                                    ? "editable-cell"
                                    : row["url"] === "" ||
                                      checkURL(row["url"]) !== 1
                                    ? "null-cell"
                                    : ""
                                }
                              >
                                {editIndex === index && editField === "url" ? (
                                  <textarea
                                    autoFocus
                                    value={row["url"]}
                                    onChange={(e) =>
                                      handleCellChange(e.target.value, index)
                                    }
                                    onBlur={handleCellBlur}
                                    onKeyPress={handleKeyPress}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  />
                                ) : (
                                  row["url"]
                                )}
                              </td>
                              <td
                                style={{
                                  backgroundColor:
                                    row["status"] === "Invalid"
                                      ? "#f5f258"
                                      : row["status"] === "Error"
                                      ? "red"
                                      : row["status"] === "Done"
                                      ? "#51f551"
                                      : "inherit",
                                }}
                              >
                                {row["status"]}
                              </td>
                              <td>{row["note"]}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <Menu
                      anchorReference="anchorPosition"
                      anchorPosition={
                        anchorEl
                          ? { top: anchorEl.mouseY, left: anchorEl.mouseX }
                          : undefined
                      }
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleDeleteRow}>Delete Row</MenuItem>
                    </Menu>
                  </div>
                )}
                <div style={{ padding: "40px" }}>
                  <Button
                    onClick={handleAddRow}
                    variant="contained"
                    color="customGray"
                    size="small"
                  >
                    Add Row
                  </Button>
                  {showAddRow && (
                    <AddRowForm
                      mode={mode}
                      open={showAddRow}
                      onCancel={handleAddRowCancel}
                      onSubmit={handleAddRowSubmit}
                      tableData={tableData}
                      setTableData={setTableData}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </ThemeProvider>
      )}
    </Authenticator>
  );
};

export default App;
