import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Autocomplete,
  TextField,
  MenuItem,
} from "@mui/material";
import { checkURL } from "../components/validation.js";
import locationData from "../config/AllCountriesData.json";

import { DEFAULT_CATEGORIES } from "../config/config.js";
export const AddRowForm = ({
  mode,
  open,
  onCancel,
  onSubmit,
  tableData,
  setTableData,
}) => {
  const [formData, setFormData] = useState({
    orderNum: tableData.length,
    city: "",
    state: "",
    country: "",
    question: "",
    category: "",
    A: "",
    B: "",
    C: "",
    D: "",
    correct_answer: "",
    fact: "",
    url: "",
    status: "",
    note: "",
  });

  const [formDataFact, setFormDataFact] = useState({
    orderNum: tableData.length,
    city: "",
    state: "",
    country: "",
    question: "",
    category: "",
    fact: "",
    url: "",
    status: "",
    note: "",
  });

  const [errors, setErrors] = useState({
    city: false,
    state: false,
    country: false,
    question: false,
    category: false,
    A: false,
    B: false,
    C: false,
    D: false,
    correct_answer: false,
    url: false,
    fact: false,
  });

  const [valid, setValid] = useState(true);
  const [loading, setLoading] = useState(false);

  const countryNames = locationData.countries.map(
    (country) => country.countryName
  );
  const [stateNames, setStateNames] = useState([]);
  const [cityNames, setCityNames] = useState([]);

  const handleSubmit = async () => {
    setLoading(true);
    let form = null;
    if (mode === "trivia") {
      form = formData;
    } else {
      form = formDataFact;
    }
    Object.keys(form).forEach(
      (k) => (form[k] = typeof form[k] == "string" ? form[k].trim() : form[k])
    );
    // const validLocation = await checkLocation(form);
    const validLocation = true;
    const validURL = checkURL(form["url"]);
    if (validLocation && validURL === 1) {
      setValid(true);
      setTableData([...tableData, form]);
      onSubmit();
    } else if (!validLocation) {
      setErrors({
        ...errors,
        city: true,
        state: true,
        country: true,
      });
      setValid(false);
    } else if (validURL !== 1) {
      setErrors({
        ...errors,
        url: true,
      });
      setValid(false);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (mode === "trivia") {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormDataFact({ ...formDataFact, [name]: value });
    }
    if (value === "") {
      setErrors({ ...errors, [name]: true });
    } else {
      setErrors({ ...errors, [name]: false });
    }
  };

  function getStateNames(countryName) {
    const country = locationData.countries.find(
      (country) => country.countryName === countryName
    );
    setStateNames(country.states.map((state) => state.stateName));
  }

  function getCityNames(countryName, stateName) {
    const country = locationData.countries.find(
      (country) => country.countryName === countryName
    );
    const state = country.states.find((state) => state.stateName === stateName);
    if (state) setCityNames(state.cities);
    else {
      setCityNames([]);
    }
  }

  const handleLocChange = (name, value) => {
    if (name === "country") {
      getStateNames(value);
      if (mode === "trivia") {
        setFormData((prevFormData) => ({
          ...prevFormData,
          country: value,
          state: "",
          city: "",
        }));
      } else {
        setFormDataFact((prevFormDataFact) => ({
          ...prevFormDataFact,
          country: value,
          state: "",
          city: "",
        }));
      }
    }
    if (name === "state") {
      if (mode === "trivia") {
        getCityNames(formData.country, value);
        setFormData((prevFormData) => ({
          ...prevFormData,
          state: value,
          city: "",
        }));
      } else {
        getCityNames(formDataFact.country, value);
        setFormDataFact((prevFormData) => ({
          ...prevFormData,
          state: value,
          city: "",
        }));
      }
    }
    if (name === "city") {
      if (mode === "trivia") {
        setFormData((prevFormData) => ({ ...prevFormData, city: value }));
      } else {
        setFormDataFact((prevFormData) => ({ ...prevFormData, city: value }));
      }
    }
    if (value === "" && name === "country") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: true }));
    }
  };

  const isFormValid = () => {
    if (mode === "trivia") {
      return Object.keys(formData)
        .filter(
          (key) => !["state", "city", "fact", "status", "note"].includes(key)
        )
        .every((key) => formData[key] !== "");
    } else {
      return Object.keys(formDataFact)
        .filter((key) => !["state", "city", "status", "note"].includes(key))
        .every((key) => formDataFact[key] !== "");
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onCancel}>
        <DialogTitle>Add Table Row</DialogTitle>
        <DialogContent className="diag-content">
          <Autocomplete
            value={mode === "trivia" ? formData.country : formDataFact.country}
            options={countryNames}
            onChange={(e, newInputValue) =>
              handleLocChange("country", newInputValue)
            }
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                required
                margin="normal"
                label="Country"
                error={errors.country}
                variant="standard"
                fullWidth
                helperText="Choose the country of the location"
              />
            )}
          />
          <Autocomplete
            value={mode === "trivia" ? formData.state : formDataFact.state}
            options={stateNames}
            onChange={(e, newInputValue) =>
              handleLocChange("state", newInputValue)
            }
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                label="State"
                error={errors.state}
                variant="standard"
                fullWidth
                helperText="Choose the state of the location"
              />
            )}
          />
          <Autocomplete
            value={mode === "trivia" ? formData.city : formDataFact.city}
            options={cityNames}
            onChange={(e, newInputValue) =>
              handleLocChange("city", newInputValue)
            }
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                label="City"
                name="city"
                error={errors.city}
                variant="standard"
                fullWidth
                helperText="Choose the city of the location"
              />
            )}
          />
          <TextField
            required
            margin="normal"
            label="Question"
            name="question"
            value={
              mode === "trivia" ? formData.question : formDataFact.question
            }
            error={errors.question}
            onChange={handleChange}
            variant="standard"
            fullWidth
            helperText="Content of the question."
          />
          <TextField
            required
            select
            margin="normal"
            label="Category"
            defaultValue=""
            variant="standard"
            error={errors.category}
            onChange={handleChange}
            name="category"
            fullWidth
            helperText="Select the category of the question."
          >
            {DEFAULT_CATEGORIES.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          {mode === "trivia" && (
            <>
              <TextField
                required
                margin="normal"
                label="A"
                name="A"
                value={formData.A}
                error={errors.A}
                onChange={handleChange}
                variant="standard"
                fullWidth
                helperText="Option A."
              />
              <TextField
                required
                margin="normal"
                label="B"
                name="B"
                value={formData.B}
                error={errors.B}
                onChange={handleChange}
                variant="standard"
                fullWidth
                helperText="Option B."
              />
              <TextField
                required
                margin="normal"
                label="C"
                name="C"
                value={formData.C}
                error={errors.C}
                onChange={handleChange}
                variant="standard"
                fullWidth
                helperText="Option C."
              />
              <TextField
                required
                margin="normal"
                label="D"
                name="D"
                value={formData.D}
                error={errors.D}
                onChange={handleChange}
                variant="standard"
                fullWidth
                helperText="Option D."
              />
              <TextField
                select
                required
                margin="normal"
                label="Correct Answer"
                defaultValue=""
                variant="standard"
                onChange={handleChange}
                error={errors.correct_answer}
                name="correct_answer"
                fullWidth
                helperText="Select the corect answer of the question."
              >
                {["A", "B", "C", "D"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </>
          )}

          <TextField
            margin="normal"
            required={mode === "trivia" ? false : true}
            label={mode === "trivia" ? "Explanation Content" : "Fact"}
            name="fact"
            value={mode === "trivia" ? formData.fact : formDataFact.fact}
            onChange={handleChange}
            variant="standard"
            fullWidth
            helperText={
              mode === "trivia"
                ? "Fact about the answer of the question (Optional)."
                : "Fact about the answer of the question"
            }
          />
          <TextField
            required
            margin="normal"
            label="Call to Action"
            name="url"
            value={formData.url}
            error={errors.url}
            onChange={handleChange}
            variant="standard"
            fullWidth
            helperText="Enter an url."
          />
          {!valid && <DialogContentText>Invalid record</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="secondary">
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!isFormValid() || loading}
            onClick={async () => await handleSubmit()}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
